<template>
  <div>
    <!-- <div class="d-flex justify-content-start align-items-center my-10">
        Toplam {{ totalItems }} müşteri bulundu.
      </div> -->
    <div
      class="d-flex align-items-center justify-content-between search-list-area"
    >
      <div class="d-flex align-items-center search-input mb-4">
        <input placeholder="Ara.." v-model="search" class="form-control mr-2" />
        <select v-model="list_length" class="form-control ml-2">
          <option value="5" selected>Seçiniz</option>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
      </div>
      <div class="d-flex">
        <router-link :to="'mulkler/mulk-ekle'">
          <button class="btn btn-primary mr-4">Mülk Ekle</button>
        </router-link>
        <b-button
          variant="outline-primary"
          class="d-flex d-md-inline-block mt-0 w-md-auto w-100"
          v-b-toggle.sidebar-right
          >Filtrele</b-button
        >
      </div>
    </div>

    <div class="custom-list">
      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Mülkler Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        <template v-slot:item.Fiyat="{ item }">
          <p class="text-dark">{{ item.Fiyat }} {{item.ParaBirimi}}</p>
        </template>
        <template v-slot:item.kiraciadi="{ item }">
          <p class="text-dark">{{item.KiraciData?.Adi}} {{item.KiraciData?.Soyadi}}</p>
        </template>
        <template v-slot:item.mulksahibiadi="{ item }">
          <p class="text-dark">{{item.MulkSahibiData?.Adi}} {{item.MulkSahibiData?.Soyadi}}</p>
        </template>
        <template v-slot:item.Icerik="{ item }">
          <Moretext :value="item" :field="'Icerik'" />
        </template>
        <template v-slot:item.IlId="{ item }">
          {{ countyNames[item.IlId] }}
        </template>
        <template v-slot:item.IlceId="{ item }">
          {{ districtName[item.IlceId] }}
        </template>
        <template v-slot:item.tip="{ item }">
          <b-badge variant="success" v-if="item.MulkTipi === 'Daire'"
            >Daire</b-badge
          >
          <b-badge variant="danger" v-else-if="item.MulkTipi === 'Bina'"
            >Bina</b-badge
          >
          <b-badge variant="info" v-else-if="item.MulkTipi === 'Arsa'"
            >Arsa</b-badge
          >
          <b-badge variant="primary" v-else>{{ item.MulkTipi }}</b-badge>
        </template>
        <template v-slot:item.MulkDurumu="{ item }">
          <b-badge variant="success" v-if="item.MulkDurumu === 'Kendi Oturuyor'"
            >Kendi Oturuyor</b-badge
          >
          <b-badge variant="danger" v-else-if="item.MulkDurumu === 'Kiracılı'"
            >Kiracılı</b-badge
          >
          <b-badge variant="info" v-else-if="item.MulkDurumu === 'Boş Daire'"
            >Boş Daire</b-badge
          >
          <b-badge variant="primary" style="white-space: break-spaces" v-else>{{ item.MulkDurumu }}</b-badge>
        </template>
        <template v-slot:item.delete="{ item }">
          <b-badge variant="danger" v-if="item.IsDeleted === true"
            >Silindi</b-badge>
            <b-badge variant="success" v-else
            >Silinmedi</b-badge>
        </template>

        <template v-slot:item.action="{ item }" class="p-0">
          <div class="d-flex justify-content-end">
            <router-link
              :to="`/mulkler/mulk-detay/${item._id.$oid}/odemeler`"
            >
              <button class="btn" >
                <i class="flaticon2-arrow text-dark p-0"></i>
              </button>
            </router-link>
          
              <button class="btn" @click="openDeleteHandler(item)">
                <i class="flaticon2-trash text-danger p-0"></i>
              </button>
          </div>
        </template>
      </List>
      <!-- Create and Actions Popups -->
    </div>

    <div class="notes-sidebar top-full">
      <b-sidebar id="sidebar-right" right>
        <div
          class="card dveb-gray-card aside-gray-card tab-card"
          style="overflow-y: auto"
        >
          <div class="card-header">FİLTRELE</div>
          <b-tabs content-class="mt-3">
            <b-tab content-class="tab-content" title="Filtre" active>
              <div class="form-group">
                <label for="sahip">Sahibi</label>
                <select name="" id="sahip" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">Şekerbank A.Ş</option>
                </select>
              </div>
              <div class="form-group">
                <label for="izle">İzlenecek</label>
                <select name="" id="izle" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">Evet</option>
                  <option value="">Hayır</option>
                </select>
              </div>
              <div class="form-group">
                <label for="durum">Durum</label>
                <select name="" id="durum" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                </select>
              </div>
              <div class="form-group">
                <label for="il">İl</label>
                <select name="" id="il" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                </select>
              </div>
              <div class="form-group">
                <label for="ilce">İlçe</label>
                <select name="" id="ilce" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                </select>
              </div>
              <div class="form-group">
                <label for="anahat">Anahtar Durumu</label>
                <select name="" id="anahat" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">Var</option>
                  <option value="">Yok</option>
                  <option value="">3</option>
                </select>
              </div>
              <div class="form-group">
                <label for="anahat" class="form-label">İşgalci Durumu</label>
                <select name="" id="anahat" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">Var</option>
                  <option value="">Yok</option>
                  <option value="">3</option>
                </select>
              </div>
              <div class="form-group">
                <label for="anahat">Ada</label>
                <input type="text" class="form-control" />
              </div>
              <div class="form-group">
                <label for="anahat">Parsel</label>
                <input type="text" class="form-control" />
              </div>
              <div class="row">
                <div class="col-md-6 col-12 mt-4">
                  <b-button variant="light" class="w-100"> Temizle </b-button>
                </div>
                <div class="col-md-6 col-12 mt-4">
                  <b-button variant="light" class="w-100"> Uygula </b-button>
                </div>
                <div class="col-12 mt-4">
                  <b-button variant="light" class="w-100">
                    Bu Filtreyi Kaydet
                  </b-button>
                </div>
              </div>
            </b-tab>
            <b-tab title="Hızlı Ara">
              <div
                class="card mt-4 dveb-card-white"
                style="border-radius: 0 !important"
              >
                <div>
                  <h4>Filtre Örnek</h4>
                  <hr />
                  <div class="row ml-auto">
                    <b-button variant="success" class="mr-4"> Uygula </b-button>
                    <b-button
                      variant="danger"
                      class=""
                      @click="openDeleteHandler(key)"
                    >
                      Sil
                    </b-button>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-sidebar>
    </div>
    <DeleteOperation
      :url="deleteOperationUrl"
      :params="deleteIds"
      @updateList="getList"
      modalid="deleteFeature"
    />
    <div>
      <b-modal v-model="openFullTextModal" title="BootstrapVue">
        <p class="my-4">Hello from modal!</p>
      </b-modal>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media (max-width: 990px) {
  .search-list-area {
    flex-direction: column;
    .search-input {
      width: 100%;
      * {
        width: 100%;
      }
    }
  }
  .search-buttons {
    flex-direction: column;
  }
}

.badges-area {
  display: flex;
  gap: 1rem;
  p {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: $dveb-primary !important;
  }
  .badges {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    .badge {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      padding: 0 !important;

      &.green {
        background-color: #44b6ae;
      }
      &.yellow {
        background-color: #ffac00;
      }
      &.red {
        background-color: red;
      }
    }
  }
}
.tapu-text {
  span {
    background-color: $dveb-divider;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { COUNTY, DISTRICT } from "@/core/services/store/location.module";

export default {
  name: "customerList",

  data() {
    return {
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      badgesEnum: ["green", "yellow", "red"],
      stepNames: ["Hazırlık", "Satış", "Devir"],
      header: [
        { text: "", value: "id", checkboxField: true, size: "50px" },
        { text: "Mülk Sahibi", sortable: true, value: "mulksahibiadi" },
        { text: "Kiracı", sortable: true, value: "kiraciadi" },
        { text: "İl", value: "IlId" },
        { text: "İlçe", value: "IlceId" },
        { text: "Fiyat", sortable: true, value: "Fiyat" },
        { text: "Açıklama", moreText: true, value: "Icerik" },
        { text: "Başlık", value: "Baslik" },
        { text: "Mülk Tipi", value: "tip" },
        { text: "Mülk Durumu", value: "MulkDurumu" },
        { text:"Durum",value:"delete"},
        { text:"Silinme Durumu",value:"delete"},
        { text: "İşlemler", value: "action" },

      ],
      items: [],
      pagination : {
              current_page: 0,
              total_pages: 0,
              total_items: 0,
              page:1
      },
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      filterList: [],
      list_length: 10,
      openFullTextModal: false,
      countyNames: [],
      districtName: [],
    };
  },
  components: {
    Moretext: () => import("@/components/global/Moretext"),
  },
  created() {
    this.getList();
  },
  methods: {
    
    getList() {
      this.loading = true;

      this.items = [];
      this.totalItems = 0;
      this.loading = true;

      let queryLimit = this.list_length ? this.list_length : 10;

      let start = (this.pagination.page -1 ) * queryLimit

      let query = `mulkler_list?offset=${
        start
      }&limit=${queryLimit}&token=${this.myUser}`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$ApiService
        .get(query)
        .then((res) => {
          if (res.status == 200) {
            this.items = res.data.result;
            this.totalItems = res.data.result.length;
            this.pagination = {
              current_page: this.pagination.page?this.pagination.page:1,
              page: this.pagination.page?this.pagination.page:1,
              total_pages: Math.ceil(this.totalItems /this.list_length),
              total_items: this.totalItems,
            };
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    
    async fetchCountyNames() {
      const countyNames = [];
      for (const item of this.items) {
        const il_adi = await this.getCountyName(item.IlId);
        countyNames[il_adi?.il_id] = il_adi?.il_adi
      }
      
      this.countyNames = countyNames;
    },
    async fetchDistrictName() {
      const districtName = [];
      for (const item of this.items) {
        const il_adi = await this.getDistrictName(item.IlceId);
        districtName[il_adi?.ilce_id] = il_adi?.ilce_adi
      }
      this.districtName = districtName;
    },

    async getCountyName(il_id) {
      try {
        const response = await this.$store.dispatch(COUNTY, il_id);
        return response[0];
      } catch (error) {
        console.error(error);
        return "İl Bulunamadı";
      }
    },
    async getDistrictName(ilce_id) {
      try {
        const response = await this.$store.dispatch(DISTRICT, ilce_id);
        if (response.length > 0) {
          return response[0];
        } else {
          return "İlçe Bulunamadı";
        }
      } catch (error) {
        console.error(error);
        return "İlce Bulunamadı";
      }
    },
    openDeleteHandler(item) {
      this.deleteOperationUrl = `mulkler_delete`;
      this.deleteIds = {token:this.myUser,id:item._id.$oid};
      this.$bvModal.show('modal-deleteOperationdeleteFeature');
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function () {
      this.getList();
    },
    list_length: function () {
      this.getList();
    },

    items() {
      if (this.items.length > 0) {
        this.fetchCountyNames();
        this.fetchDistrictName();
      }
    },
  },

  computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>
